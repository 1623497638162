import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { AppLogger } from "../../../Helpers/AppLogger"
import { GetCertificateListRequest } from "../../../api/Authentication/AuthReducer"
// import AddCeritificateForm from '../AddCeritificateForm';
import { get } from 'lodash';
import styles from "./UploadCertificate.module.scss"
import AppImages from '../../../Helpers/AppImages';
import SideMenu from '../SideMenu';

function UploadCertificate() {
     const dispatch = useDispatch()
     const { token, certificatesList } = useSelector(state => state.AuthReducer)
     const [finalCertificatelist, setFinalCertificatelist] = useState([])
     const [searchValue, setSearchValue] = useState("")
     const [filteredCertificates, setFilteredCertificates] = useState([])
     // const [toggleModal, setToggleModal] = useState(false)

     useEffect(() => {
          getCertificates()
     }, [])

     const getCertificates = () => {
          dispatch(GetCertificateListRequest({ token: token }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("GetCertificateListRequest", response)
               })
               .catch(err => {
                    AppLogger("GetCertificateListRequest ", err)
               })
     }

     useEffect(() => {
          var finalList = []
          if (certificatesList) {
               certificatesList.forEach(element => {
                    AppLogger("element", element)
                    finalList.push({
                         expiration_date: get(element, "expiration_date", ""),
                         image_path: get(element, "image_path", ""),
                         issue_date: get(element, "issue_date", ""),
                         organization_name: get(element, "organization_name", ""),
                         name: get(element, "user.name", ""),
                         file: get(element, "file", "")
                    })
               });
               AppLogger("Final List", finalList)
               setFinalCertificatelist(finalList)
               setFilteredCertificates(finalList)
          }
     }, [certificatesList])

     const onSearch = (val) => {
          setSearchValue(val.toLowerCase());

          let value = val.toLowerCase();
          let updatedCertificates = [];

          if (val.replace(/\s/g, '').length) {
               updatedCertificates = finalCertificatelist.filter((uItem) => {
                    return uItem.name.toLowerCase().search(value) != -1;
               });
               setFilteredCertificates(updatedCertificates);
          } else {
               setFilteredCertificates(finalCertificatelist)
          }
     };

     const columnsT2 = [
          {
               // title: 'Name',
               title: () => { return <div><p>Name</p></div> },
               dataIndex: 'name',
          },
          {
               // title: 'Issue Date',
               title: () => { return <div><p>Issue Date</p></div> },
               dataIndex: 'issue_date',
          },
          {
               // title: 'Expiration Date',
               title: () => { return <div><p>Expiration Date</p></div> },
               dataIndex: 'expiration_date',
          },
          {
               // title: 'Upload',
               title: () => { return <div><p>Upload</p></div> },
               key: 'upload',
               render: (e) => <button className={styles.certificateBtn} onClick={() => window.open(e.file, "_blank")}>Certificate</button>,
          }
     ];

     const dataT2 = filteredCertificates ? filteredCertificates : [
          {
               key: '1',
               name: 'John Brown',
               issueDate: "11/14/2022",
               expiratDate: "11/15/2022",
          },
          {
               key: '2',
               name: 'John Brown',
               issueDate: "11/14/2022",
               expiratDate: "11/15/2022",
          },
          {
               key: '3',
               name: 'John Brown',
               issueDate: "11/14/2022",
               expiratDate: "11/15/2022",
          },
     ];

     return (
          <div className={styles.mainContainer}>
               <SideMenu selectedIndex={3} />
               <div className={styles.container}>
                    <div className={styles.upperCon}>
                         <div>
                              {/* <button onClick={() => setToggleModal(true)} className={styles.newCertificateBtn}>Add New Certificate</button> */}
                              {/* <button className={styles.changeTempBtn}>Change Template</button> */}
                         </div>

                         <div className={styles.searchBar}>
                              <input
                                   value={searchValue}
                                   onChange={(e) => onSearch(e.target.value)}
                                   type={"text"}
                                   style={{ color: "#ffffff", }}
                                   placeholder={"Search file by name..."}
                              />
                              <img onClick={() => onSearch(searchValue)} className={styles.iconStyl} src={AppImages.search} />
                         </div>

                    </div>

                    <Table rowClassName={(_r, i) => (i % 2 ? styles.odd : styles.even)} pagination={false} className={styles.table} columns={columnsT2} dataSource={dataT2} />

                    {/* <AddCeritificateForm
                    showModal={toggleModal}
                    setShowModal={(val) => setToggleModal(val)}
                    getNewCertificates={() => getCertificates()}
               /> */}
               </div>
          </div>
     )
}

export default UploadCertificate