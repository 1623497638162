import { AppConstants } from "../../Helpers/AppConstants"
import axios from "axios"
// import Cookies from 'js-cookie';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "XCSRF-TOKEN"
// axios.defaults.headers.common['X-CSRF-TOKEN'] = Cookies.get('csrftoken');
// Cookies.set('csrftoken', '')

const axiosRequest = axios.create({
  baseURL: AppConstants.BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    // "Access-Control-Allow-Origin": '*',
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    "Content-Type": "application/json",
    // Connection: "keep-alive",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0"

  }
})

function apiLoginRequest(payload) {
  // AppLogger("Login Rquest Payload", payload)
  return axiosRequest.post(`/api/v1/admin/`, payload.body)
}

function apiUploadContentRequest(payload) {
  // console.log("payload body for upload content ==== ", payload);
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  return axiosRequest.post(`/api/v1/uploadcontent`, payload.body, config)
}

function apiGetUploadedContentRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  return axiosRequest.get(`/api/v1/videos/admin/${payload.ageGroup}`, config)
}

function apiDeleteLevelRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  return axiosRequest.delete(`/api/v1/videos/delete/${payload.levelId}`, config)
}

function apiGetAgeGroupsRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  return axiosRequest.get(`/api/v1/age/`, config)
}

function apiGetUserAnalyticsRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  return axiosRequest.get(`/api/v1/dashboard`, config)
}

function apiGetUserListRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  return axiosRequest.get(`/api/v1/userlist`, config)
}

function apiGetRecentUserListRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  return axiosRequest.get(`/api/v1/userlist/recent`, config)
}

function apiGetRecentCertificatesRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  return axiosRequest.get(`/api/v1/certificatelist/recent`, config)
}

function apiGetCertificateListRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  return axiosRequest.get(`/api/v1/certificatelist`, config)
}

function apiDeleteUserRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  let path = `/api/v1/user/${payload.id}/`
  return axiosRequest.delete(path, config)
}

function apiRedeemedUserRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  const body = {
    redeemed: payload.redeemed
  }

  let path = `/api/v1/user/redeem/${payload.userId}`
  return axiosRequest.put(path, body, config)
}


function apiExportUserListRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  let path = `/api/v1/userlist/csv`
  return axiosRequest.get(path, config)
}

function apiAddCertificateRequest(payload) {
  console.log("payload body for upload content ==== ", payload);

  var data = new FormData();
  for (var key in payload.body) {
    data.append(key, payload.body[key]);
  }

  const config = {
    headers: {
      Authorization: `Token ${payload.token}`,
      'content-type': 'multipart/form-data'
    }
  }
  return axiosRequest.post(`/api/v1/certificate/${payload.userId}`, data, config)
}


function apiGetUserListForCertificateRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }

  return axiosRequest.get(`/api/v1/certificate/eligibleuser`, config)
}

function apiBanUser(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` }
  }
  return axiosRequest.put(`/api/v1/user/ban/${payload.id}`, payload.body, config)
}

export const AuthApiServices = {
  apiUploadContentRequest,
  apiGetUserAnalyticsRequest,
  apiLoginRequest,
  apiGetUserListRequest,
  apiGetRecentUserListRequest,
  apiDeleteUserRequest,
  apiGetRecentCertificatesRequest,
  apiGetCertificateListRequest,
  apiGetUploadedContentRequest,
  apiAddCertificateRequest,
  apiGetUserListForCertificateRequest,
  apiBanUser,
  apiGetAgeGroupsRequest,
  apiRedeemedUserRequest,
  apiExportUserListRequest,
  apiDeleteLevelRequest
}