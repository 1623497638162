import React from "react"
import styles from "./UploadContent.module.scss"
import AppImages from "../../../Helpers/AppImages"

export default function CheckBox(props) {
  const { optionItem, handleOptChange, index, handleRemoveOption, showRemove } = props


  return (
    <div className={styles.checkboxContain}>
      <input
        className={styles.checkbox}
        type={"checkbox"}
        checked={optionItem.is_correct}
        onChange={(e) => handleOptChange(e.target.checked, "is_correct", index)}
      />
      <div>

        <input
          value={optionItem.value}
          className={styles.inputAnswers}
          type={"text"}
          onChange={e => handleOptChange(e.target.value, "value", index)}
        />
        <p className={styles.correctAns}>Correct Answer</p>
      </div>
      {showRemove &&
        <img
          onClick={() => handleRemoveOption()}
          className={styles.deleteIcon} src={AppImages.deleteIcon}
        />
      }
    </div>
  )
}
