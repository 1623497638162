import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Helmet } from "react-helmet"
import { RouteNames } from "../Helpers/AppConstants"
import styled from "styled-components"
import Login from "../screens/LoginScr/Login"
import UploadCertificate from "../screens/components/UploadCertificate"
import UploadContent from "../screens/components/UploadContent"
import ListOfUsers from "../screens/components/ListOfUsers"
import DashboardComp from "../screens/components/DashboardComp"
import PageNotFound from "../screens/components/PageNotFound"

const AppWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  overflow: hidden;
`

const AppRoutes = () => {
  return (
    <AppWrapper>
      <BrowserRouter>
        <Helmet
          titleTemplate="%s - Ardent Credit Web"
          defaultTitle="Ardent Credit Web"
        >
          <meta name="description" content="Ardent Credit Union Web" />
        </Helmet>
        <Routes>
          <Route path={RouteNames.login} index element={<Login />} />
          <Route path={RouteNames.dashboard} index element={<DashboardComp />} />
          <Route path={RouteNames.listOfUsers} index element={<ListOfUsers />} />
          <Route path={RouteNames.uploadCertificate} index element={<UploadCertificate />} />
          <Route path={RouteNames.uploadContent} index element={<UploadContent />} />
          <Route path={RouteNames.pageNotFound} index element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  )
}

export default AppRoutes
