import React from "react"

const Button = props => {
  const { className, title, handleClick, loading } = props
  return (
    <button
      // style={{ textTransform: 'uppercase' }}
      onClick={handleClick}
      disabled={loading == "pending" ? true : false}
      className={className}
    >
      {title}
    </button>
  )
}

export default Button
