import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import { BanUserRequest, DeleteUserRequest, ExportUserListRequest, GetUserListRequest, RedeemedUserRequest } from '../../../api/Authentication/AuthReducer';
import { failureToastMessage, successToastMessage } from '../../../Helpers/ToastMessage';
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { AppLogger } from '../../../Helpers/AppLogger';
import { getImageSource, localeDateFormat } from '../../../Helpers/AppConstants';
import { get } from 'lodash';
import AppImages from '../../../Helpers/AppImages';
import Highlighter from 'react-highlight-words';
import styles from "./ListOfUsers.module.scss"
import CustomModal from "../CustomModal"
import SideMenu from '../SideMenu';

function ListOfUsers() {
     const dispatch = useDispatch()
     const { loading, token, userList } = useSelector(state => state.AuthReducer)
     const [searchText, setSearchText] = useState('');
     const [searchedColumn, setSearchedColumn] = useState('');
     const [finalUserList, setFinalUserList] = useState([])
     const searchInput = useRef(null);
     const [toggleModal, setToggleModal] = useState(false)
     const [showRedeemModal, setShowRedeemModal] = useState(false)
     const [blockModal, setBlockModal] = useState(false)
     const [unblockModal, setUnBlockModal] = useState(false)
     const [selectedUser, setSelectedUser] = useState(0)
     const [searchValue, setSearchValue] = useState("")
     const [filteredUserList, setFilteredUserList] = useState([])

     useEffect(() => {
          getUsersList()
     }, [])

     // Function to block the user
     const blockUser = (id, status) => {
          const body = {
               is_ban: !status
          }
          setUnBlockModal(false)
          setBlockModal(false)
          dispatch(BanUserRequest({ body, token, id }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("Block User response", response)
                    if (response) {
                         { status == false ? successToastMessage("User blocked successfully") : successToastMessage("User unblocked successfully") }
                    }
                    const updatedUserList = filteredUserList.map(user => {
                         if (user.id === id) {
                              return {
                                   ...user,
                                   is_ban: !status,
                              }
                         }
                         return user
                    })
                    setFilteredUserList(updatedUserList)
               })

               .catch(err => {
                    AppLogger("Block User error", err)
                    if (err.message) {
                         failureToastMessage(err.message)
                    }
                    if (err.detail) {
                         failureToastMessage(err.detail)
                    }
                    else {
                         failureToastMessage("Something went wrong")
                    }
               })
     }

     const getUsersList = () => {
          dispatch(GetUserListRequest({ token: token }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("Get UserListRequest", response)
               })
               .catch(err => {
                    AppLogger("Get UserListRequest ", err)
               })
     }

     useEffect(() => {
          var finalList = []
          if (userList) {
               userList.forEach(element => {
                    finalList.push({
                         age: get(element, "age", ""),
                         avatar: get(element, "avatar", ""),
                         course_completed: get(element, "course_completed", ""),
                         id: get(element, "id", ""),
                         completion_date: get(element, "completion_date", ""),
                         last_login: get(element, "last_login", ""),
                         last_used: get(element, "last_used", ""),
                         is_over_13: get(element, "is_over_13", ""),
                         redeemed: get(element, "redeemed", ""),
                         is_ban: get(element, "is_ban", ""),
                         completed_videos: get(element, "completed_videos", ""),
                         date_joined: get(element, "date_joined", ""),
                         name: get(element, "name", ""),
                         parent: get(element, "parent", ""),
                         email: get(element, "parent", null) != null ? get(element, "parent.email", "") : get(element, "email", ""),
                         zip_code: get(element, "parent", null) != null ? get(element, "parent.zip_code", "") : get(element, "zip_code", ""),
                         email_updated_at: get(element, "email_updated_at", "")
                    })
               });
               setFinalUserList(finalList)
               setFilteredUserList(finalList)
          }
     }, [userList])

     const handleDeleteUser = (e) => {
          setToggleModal(false)
          dispatch(DeleteUserRequest({ token: token, id: e.id }))
               .then(unwrapResult)
               .then(response => {
                    successToastMessage("User Deleted Successfully!")
                    getUsersList()
               })
               .catch(err => {
                    AppLogger("Delete UserRequest error", err)
                    if (err?.detail) {
                         failureToastMessage(err.detail)
                    }
               })
     }

     const handleExportUserList = () => {
          dispatch(ExportUserListRequest({ token: token }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("ExportUserListRequest response", response)
                    // successToastMessage("")
                    if (response) {
                         window.open(response.url, "_blank")
                    }
               })
               .catch(err => {
                    AppLogger("ExportUserListRequest error", err)
                    if (err?.detail) {
                         failureToastMessage(err.detail)
                    }
               })
     }

     const searchUser = (val) => {
          setSearchValue(val.toLowerCase());

          let value = val.toLowerCase();
          let updatedUserList = [];

          if (val.replace(/\s/g, '').length) { // checking if value contain only spaces or not
               updatedUserList = finalUserList.filter((uItem) => {
                    if (get(uItem, "email", "") && get(uItem, "name", "") && get(uItem, "date_joined", "")) {
                         const regDate = new Date(uItem.date_joined).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
                         // AppLogger("regDate", regDate)
                         return uItem.email.toLowerCase().includes(value) ||
                              uItem.name.toLowerCase().search(value) != -1 ||
                              regDate.search(value) != -1
                    }
               });
               setFilteredUserList(updatedUserList);
          } else {  // string only contains whitespace 
               setFilteredUserList(finalUserList)
          }
     };
     const data = filteredUserList


     const handleRedemmed = (e) => {
          AppLogger("eee eee ", e)
          setShowRedeemModal(false)
          dispatch(RedeemedUserRequest({ token: token, userId: e.id, redeemed: !e.redeemed }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("RedeemedUserRequest response", response)
                    if (response) {
                         successToastMessage(response.redeemed ? "User redeemed successfully" : "User un redeemed successfully")
                         const updatedUserList = filteredUserList.map(user => {
                              if (user.id === e.id) {
                                   return {
                                        ...user,
                                        redeemed: response.redeemed,
                                   }
                              }
                              return user
                         })
                         setFilteredUserList(updatedUserList)
                    }
               })
               .catch(err => {
                    AppLogger("Unable to redeem error", err)
                    if (err?.detail) {
                         failureToastMessage(err.detail)
                    }
               })
     }

     const handleSearch = (selectedKeys, confirm, dataIndex,) => {
          confirm();
          setSearchText(selectedKeys[0]);
          setSearchedColumn(dataIndex);
     };

     const handleReset = (clearFilters) => {
          clearFilters();
          setSearchText('');
     };

     const getColumnSearchProps = (dataIndex) => ({
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
               <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                         ref={searchInput}
                         placeholder={`Search ${dataIndex}`}
                         value={selectedKeys[0]}
                         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                         style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                         <Button
                              type="primary"
                              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                              icon={<SearchOutlined />}
                              size="small"
                              style={{ width: 90 }}
                         >
                              Search
                         </Button>
                         <Button
                              onClick={() => clearFilters && handleReset(clearFilters)}
                              size="small"
                              style={{ width: 90 }}
                         >
                              Reset
                         </Button>
                         <Button
                              type="link"
                              size="small"
                              onClick={() => {
                                   confirm({ closeDropdown: false });
                                   setSearchText((selectedKeys)[0]);
                                   setSearchedColumn(dataIndex);
                              }}
                         >
                              Filter
                         </Button>
                         <Button
                              type="link"
                              size="small"
                              onClick={() => {
                                   close();
                              }}
                         >
                              close
                         </Button>
                    </Space>
               </div>
          ),
          filterIcon: (filtered) => (
               <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          onFilter: (value, record) =>
               record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes((value).toLowerCase()),
          onFilterDropdownOpenChange: (visible) => {
               if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
               }
          },
          render: (text) =>
               searchedColumn === dataIndex ? (
                    <Highlighter
                         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                         searchWords={[searchText]}
                         autoEscape
                         textToHighlight={text ? text.toString() : ''}
                    />
               ) : (
                    text
               ),
     });

     const columns = [
          {
               title: () => { return <div><p>Image</p></div> },
               dataIndex: 'avatar',
               key: 'image',
               render: (e) =>
                    <>
                         <img style={{ height: "40px", width: "40px", borderRadius: 25 }} src={getImageSource(e?.image)} />
                    </>
               ,
          },
          {
               title: () => { return <div><p>Name</p></div> },
               dataIndex: 'name',
          },
          {
               title: () => { return <div><p>Reg. Date</p></div> },
               dataIndex: 'date_joined',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p>Email</p></div> },
               dataIndex: 'email',
          },
          {
               title: () => { return <div><p>Email Update Date</p></div> },
               dataIndex: 'email_updated_at',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p>ZIP</p></div> },
               dataIndex: 'zip_code',
          },
          {
               title: () => { return <div><p># of Videos Completed</p></div> },
               dataIndex: 'completed_videos',
          },
          {
               title: () => { return <div><p>Completion Date</p></div> },
               dataIndex: 'completion_date',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p>Last Login</p></div> },
               dataIndex: 'last_login',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p>Last Use</p></div> },
               dataIndex: 'last_used',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p>Is Over 13</p></div> },
               key: 'isover13',
               render: (e) =>
                    <label className="checkbox-container">
                         <input type="checkbox" checked={e.is_over_13} />
                         <span className="checkmark"></span>
                    </label>,
          },
          {
               title: () => { return <div><p>Redeemed</p></div> },
               key: 'redeemed',
               render: (e) =>
                    <label className="checkbox-container"
                         onClick={() => {
                              setShowRedeemModal(true)
                              setSelectedUser(e)
                         }}
                    >
                         <input type="checkbox"
                              checked={e.redeemed}
                         // value={e.redeemed}
                         // onChange={() => null}
                         />
                         <span className="checkmark"></span>
                    </label>,
          },
          {
               title: () => { return <div><p>Actions</p></div> },
               key: 'actions',
               render: (e) =>
                    <div>
                         <img
                              onClick={() => {
                                   setToggleModal(true)
                                   setSelectedUser(e)
                              }}
                              className={styles.deleteIcons} src={AppImages.delete}
                         />
                         {e.is_ban === true ? <img
                              onClick={() => {
                                   setSelectedUser(e)
                                   setUnBlockModal(true)
                              }}
                              className={styles.deleteIcons} src={AppImages.ban} /> :
                              <img onClick={() => {
                                   setBlockModal(true)
                                   setSelectedUser(e)
                              }}
                                   className={styles.deleteIcons} src={AppImages.block} />}
                    </div>,
          }
     ];

     return (
          <div className={styles.mainContainer}>
               <SideMenu selectedIndex={1} />
               <div className={styles.container}>
                    <div className={styles.upperCon}>
                         <button className={styles.newUserBtn} onClick={() => handleExportUserList()}>
                              Export User List
                         </button>
                         <div className={styles.searchBar}>
                              <input
                                   value={searchValue}
                                   onChange={(e) => searchUser(e.target.value)}
                                   style={{ color: "#ffffff", }}
                                   type={"text"}
                                   placeholder={"Search by name, email or reg. date..."}
                              />
                              <img onClick={() => searchUser(searchValue)} className={styles.iconStyl} src={AppImages.search} />
                         </div>

                    </div>

                    <Table rowClassName={(_r, i) => (i % 2 ? styles.odd : styles.even)} pagination={false} className={styles.table} columns={columns} dataSource={data} />
                    <CustomModal
                         showModal={toggleModal}
                         setShowModal={(val) => setToggleModal(val)}
                         desc="Are you sure you want to delete this user?"
                         btnTitle="Delete"
                         onBtnClick={() => handleDeleteUser(selectedUser)}
                    />
                    <CustomModal
                         showModal={blockModal}
                         setShowModal={(val) => setBlockModal(val)}
                         desc="Are you sure you want to block this user?"
                         btnTitle="Block"
                         onBtnClick={() => blockUser(selectedUser.id, selectedUser.is_ban)}
                    />
                    <CustomModal
                         showModal={unblockModal}
                         setShowModal={(val) => setUnBlockModal(val)}
                         desc="Are you sure you want to unblock this user?"
                         btnTitle="Un Block"
                         onBtnClick={() => blockUser(selectedUser.id, selectedUser.is_ban)}
                    />
                    <CustomModal
                         showModal={showRedeemModal}
                         setShowModal={(val) => setShowRedeemModal(val)}
                         desc={`Are you sure you want to ${selectedUser.redeemed ? "Un Redeem" : "Redeem"} this user?`}
                         btnTitle={`${selectedUser.redeemed ? "Un Redeem" : "Redeem"}`}
                         onBtnClick={() => handleRedemmed(selectedUser)}
                    />
               </div>
          </div>
     )
}

export default ListOfUsers
