import toast from "react-hot-toast"

const style = {
     fontFamily: 'GothamMedium',
}

export const successToastMessage = (message) => {
     toast.dismiss()
     toast.success(message)
}

export const failureToastMessage = (message) => {
     toast.dismiss()
     toast.error(message)
}

export const warningToastMessage = (message) => {
     toast.dismiss()
     toast(message)
}
