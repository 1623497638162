import React, { useEffect, useState } from "react"
import { AppLogger } from "../../../Helpers/AppLogger"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DeleteLevelRequest, GetAgeGroupsRequest, GetUploadedContentRequest } from "../../../api/Authentication/AuthReducer";
import { unwrapResult } from "@reduxjs/toolkit"
import styles from "./UploadContent.module.scss"
import Form from 'react-bootstrap/Form';
import CustomLoader from "../Loader";
import Section from "./Section"
import AppImages from "../../../Helpers/AppImages";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./uploadcontent.css";
import { failureToastMessage, successToastMessage } from "../../../Helpers/ToastMessage";
import CustomModal from "../CustomModal";
import SideMenu from "../SideMenu";

function UploadContent() {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState("idle")
  const [selectedAgeGrp, setSelectedAgeGrp] = useState(0)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedLevelId, setSelectedLevelId] = useState(0)
  const { uploadedContentResp, token, ageGroupsList } = useSelector(state => state.AuthReducer)
  const inputRef = useRef([]);
  // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" // using for testing

  const [sections, setSections] = useState([
    // {
    //   id: 1,
    //   thumbnail: "",
    //   base64URL: "",
    //   videoUrl: "",
    //   questions: []
    // },
    // {
    //   id: 2,
    //   thumbnail: "",
    //   base64URL: "",
    //   videoUrl: "",
    //   questions: []
    // },
    // {
    //   id: 3,
    //   thumbnail: "",
    //   base64URL: "",
    //   videoUrl: "",
    //   questions: []
    // },
    // {
    //   id: 4,
    //   thumbnail: "",
    //   base64URL: "",
    //   videoUrl: "",
    //   questions: []
    // },
    // {
    //   id: 5,
    //   thumbnail: "",
    //   base64URL: "",
    //   videoUrl: "",
    //   questions: []
    // },
    // {
    //   id: 6,
    //   thumbnail: "",
    //   base64URL: "",
    //   videoUrl: "",
    //   questions: []
    // }
  ])

  useEffect(() => {
    getAgeGroups()
  }, [])

  const getUplaodedContent = (ageGroup) => {
    setSections([])
    dispatch(GetUploadedContentRequest({ token: token, ageGroup: ageGroup }))
      .then(unwrapResult)
      .then(response => {
        AppLogger("Get Uploaded Content Request", response)
        if (response && response.length > 0) {
          handleUploadedContentData(response)
        } else {
          setSections([])
        }
      })
      .catch(err => {
        AppLogger("Get Uploaded Content Request", err)
      })
  }

  const getAgeGroups = () => {
    dispatch(GetAgeGroupsRequest({ token: token }))
      .then(unwrapResult)
      .then(response => {
        AppLogger("Get AgeGroups Request Request", response)

        if (response && response.length > 0) {
          setSelectedAgeGrp(response[0].id)
        }
      })
      .catch(err => {
        AppLogger("Get AgeGroups Request Request", err)
      })
  }

  useEffect(() => {
    AppLogger("selected age group", selectedAgeGrp);
    if (selectedAgeGrp) {
      getUplaodedContent(selectedAgeGrp)
    }
  }, [selectedAgeGrp])

  // // getting levels that are already uploaded
  const handleUploadedContentData = (uploadedContentResp) => {
    var sectionList = []
    if (uploadedContentResp) {
      if (uploadedContentResp.length > 0) {
        setLoader("pending")

        uploadedContentResp.forEach(element => {
          var questArray = []

          element.question_video.forEach((quests, index) => {
            var optionArray = []

            quests.video_option.forEach((opt) => {
              optionArray.push({
                number: opt.number,
                value: opt.value,
                is_correct: opt.is_correct
              })
            })

            questArray.push({
              number: quests.number,
              value: quests.value,
              option: optionArray
            })
          })

          sectionList.push({
            id: element.id,
            base64URL: "",
            thumbnail: element.thumbnail,
            videoUrl: element.video,
            questions: questArray,
          })
        })
        setSections(sectionList)
        setLoader("idle")
      }
    }
  }

  useEffect(() => {
    inputRef.current = inputRef.current.slice(0, sections.length);
    AppLogger("sections....", sections)
  }, [sections]);

  const getBase64 = async file => {
    return new Promise(resolve => {
      let fileInfo
      let baseURL = ""
      let reader = new FileReader()

      reader.readAsDataURL(file) // Convert the file to base64 text
      reader.onload = () => { // on reader load somthing...
        // Make a fileInfo Object
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const handleFileInputChange = (e, index) => {
    // AppLogger("indexaaaaaa", index)
    try {
      if (e.target.files) {
        let { file } = e.target.files[0]
        file = e.target.files[0]

        getBase64(file)
          .then(result => {
            file["base64"] = result
            handleSectionChange(file.base64, "base64URL", index)
            handleSectionChange(URL.createObjectURL(file), "videoUrl", index)
            try {
              getVideoCover(file, 0.5).then((result) => {
                // AppLogger("cover photo ", URL.createObjectURL(result));
                blobToBase64(URL.createObjectURL(result))
                  .then(base64String => {
                    // AppLogger(base64String)
                    handleSectionChange(base64String, "thumbnail", index)
                  });
              })
            } catch (ex) {
              AppLogger("ERROR converting video cover: ", ex);
            }
          })
          .catch(err => {
            AppLogger("error converting video to base64", err)
          })
      }
    } catch (err) {
      AppLogger('error handleFileInputChange', err)
    }
  }

  const blobToBase64 = async (url) => { // to change the thumbnail to base 64
    return new Promise(async (resolve, _) => {
      const response = await fetch(url);
      const blob = await response.blob(); // getting blob from .blob()
      const fileReader = new FileReader(); // initiating a file reader
      fileReader.readAsDataURL(blob);    // reading file
      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      }
    });
  };

  const getVideoCover = (file, seekTo = 0.0) => {  //  converting video url to thumbnail
    AppLogger("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        reject("error when loading video file", ex);
      });
      videoPlayer.addEventListener('loadedmetadata', () => {
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        videoPlayer.addEventListener('seeked', () => {
          AppLogger('video is now paused at %ss.', seekTo);
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            "image/jpeg",
            0.75
          );
        });
      });
    });
  }

  const handleSectionChange = (value, objKey, index) => {
    const list = [...sections]
    list[index][objKey] = value
    setSections(list)
  }

  const addLevel = () => {
    setSections([...sections, {
      id: 0,
      thumbnail: "",
      base64URL: "",
      videoUrl: "",
      questions: []
    }])
  }

  const removeLevel = (index, id) => {
    setShowDeleteModal(false)
    if (id == 0) {
      removeArrayElement(index)
    } else {
      dispatch(DeleteLevelRequest({ token: token, levelId: id }))
        .then(unwrapResult)
        .then(response => {
          AppLogger("DeleteLevelRequest Request", response)
          successToastMessage(response)
          removeArrayElement(index)
        })
        .catch(err => {
          AppLogger("DeleteLevelRequest Request", err)
          failureToastMessage(err?.message)
        })
    }
  }

  const removeArrayElement = (index) => {
    const list = [...sections]
    list.splice(index, 1)
    setSections(list)
  }

  const renderLevelSections = () => {
    return (
      <div>
        {sections.map((item, i) => {
          return (
            <div className={styles.contentCont} key={i}>
              <div className={`${styles.videoCont} my-3`}>
                <div className={styles.videoInnerCon}>
                  {/* <img src={item.thumbnail} className={styles.videothumb} /> */}
                  <p className={styles.indicator}>{i + 1}</p>
                  {/* <div>
                    <input
                      ref={r => (inputRef.current[i] = r)}
                      className={styles.hiddenBtn}
                      accept="video/mp4,video/*"
                      type="file"
                      name="Upload Video"
                      onChange={(e) => handleFileInputChange(e, i)} />
                  </div> */}
                  <div className={styles.videoUrl}>
                    <input
                      type={"text"}
                      placeholder={"Enter Video URL"}
                      className={styles.videoUrlInput}
                      value={item.videoUrl}
                      onChange={(e) => {
                        handleSectionChange(e.target.value, "videoUrl", i)
                        handleSectionChange("updated", "base64URL", i)
                      }}
                    />
                  </div>
                  {/* <button
                    onClick={() => {
                      if (inputRef.current[i]) {
                        inputRef.current[i].click()
                      }
                    }}
                    className={styles.videoBtn}
                  >
                    Add Video Link
                  </button> */}
                </div>
              </div>
              <Section
                callUploadContentApi={() => getUplaodedContent(selectedAgeGrp)}
                sectionDetail={sections[i]}
                updatedQuestions={handleSectionChange}
                index={i}
                selectedAgeGrp={selectedAgeGrp}
              />
              <div className={styles.separator} />
              {i == sections.length - 1 &&
                <div style={{ cursor: "pointer", position: 'absolute', top: -28, right: 50 }}>
                  <img
                    style={{ width: 24, height: 24, }}
                    src={AppImages.remove}
                    onClick={() => {
                      setSelectedIndex(i)
                      setSelectedLevelId(item.id)
                      setShowDeleteModal(true)
                    }}
                  />
                </div>
              }
            </div>
          )
        })}

        <div className={styles.addLevelCont} >
          <button className={styles.questionsBtn} onClick={() => addLevel()}>
            Add Level
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.mainContainer}>
      <SideMenu selectedIndex={2} />
      <div className={styles.container}>
        {loader == "pending" && <CustomLoader />}
        <div className={styles.mainCon}>
          {ageGroupsList && ageGroupsList.length > 0 ?
            <div style={{ marginBottom: 16 }}>
              <p className="title">Please Select Age Group</p>
              <Tabs
                fill
                id="fill-tab-example"
                defaultActiveKey={ageGroupsList[0].id}
                className="tabStyles"
                onSelect={(value) => setSelectedAgeGrp(parseInt(value))}
              >
                {ageGroupsList.map((item, index) =>
                  <Tab eventKey={item.id} title={item.title} key={index}>
                    {renderLevelSections()}
                  </Tab>
                )}
              </Tabs>
            </div>
            :
            <div>
              <p className="title">No age groups found</p>
            </div>
          }
          <CustomModal
            showModal={showDeleteModal}
            setShowModal={(val) => setShowDeleteModal(val)}
            desc={"Are you sure you want to delete this level?"}
            btnTitle={"Delete"}
            onBtnClick={() => removeLevel(selectedIndex, selectedLevelId)}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadContent