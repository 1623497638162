import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { AuthApiServices } from "./AuthApiServices"

export const LoginRequest = createAsyncThunk(
  "AuthReducer/LoginRequest",
  async payload => {
    const response = await AuthApiServices.apiLoginRequest(payload)
    return response.data
  }
)

export const GetUserAnalyticsRequest = createAsyncThunk(
  "AuthReducer/GetUserAnalyticsRequest",
  async payload => {
    const response = await AuthApiServices.apiGetUserAnalyticsRequest(payload)
    return response.data
  }
)

export const UploadContentRequest = createAsyncThunk(
  "AuthReducer/UploadContentRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiUploadContentRequest(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const BanUserRequest = createAsyncThunk(
  "AuthReducer/BanUserRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiBanUser(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const GetUploadedContentRequest = createAsyncThunk(
  "AuthReducer/GetUploadedContentRequest",
  async payload => {
    const response = await AuthApiServices.apiGetUploadedContentRequest(payload)
    return response.data
  }
)

export const DeleteLevelRequest = createAsyncThunk(
  "AuthReducer/DeleteLevelRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiDeleteLevelRequest(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const GetAgeGroupsRequest = createAsyncThunk(
  "AuthReducer/GetAgeGroupsRequest",
  async payload => {
    const response = await AuthApiServices.apiGetAgeGroupsRequest(payload)
    return response.data
  }
)

export const GetUserListRequest = createAsyncThunk(
  "AuthReducer/GetUserListRequest",
  async payload => {
    const response = await AuthApiServices.apiGetUserListRequest(payload)
    return response.data
  }
)

export const GetRecentUserListRequest = createAsyncThunk(
  "AuthReducer/GetRecentUserListRequest",
  async payload => {
    const response = await AuthApiServices.apiGetRecentUserListRequest(payload)
    return response.data
  }
)

export const DeleteUserRequest = createAsyncThunk(
  "AuthReducer/DeleteUserRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiDeleteUserRequest(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const RedeemedUserRequest = createAsyncThunk(
  "AuthReducer/RedeemedUserRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiRedeemedUserRequest(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const ExportUserListRequest = createAsyncThunk(
  "AuthReducer/ExportUserListRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiExportUserListRequest(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)


export const AddCertificateRequest = createAsyncThunk(
  "AuthReducer/AddCertificateRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiAddCertificateRequest(payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const GetUserListForCertificateRequest = createAsyncThunk(
  "AuthReducer/GetUserListForCertificateRequest",
  async payload => {
    const response = await AuthApiServices.apiGetUserListForCertificateRequest(payload)
    return response.data
  }
)


export const GetCertificateListRequest = createAsyncThunk(
  "AuthReducer/GetCertificateListRequest",
  async payload => {
    const response = await AuthApiServices.apiGetCertificateListRequest(payload)
    return response.data
  }
)

export const GetRecentCertificatesRequest = createAsyncThunk(
  "AuthReducer/GetRecentCertificatesRequest",
  async payload => {
    const response = await AuthApiServices.apiGetRecentCertificatesRequest(payload)
    return response.data
  }
)

// export const PasswordResetRequest = createAsyncThunk(
//   "AuthReducer/PasswordResetRequest",
//   async payload => {
//     const response = await AuthApiServices.apiPasswordResetRequest(payload)
//     return response.data
//   }
// )

export const SaveDataRequest = createAsyncThunk(
  "AuthReducer/SaveDataRequest",
  async payload => {
    return payload
  }
)

export const LogoutRequest = createAsyncThunk(
  "AuthReducer/LogoutRequest",
  async payload => {
    return payload
  }
)

const initialState = {
  token: null,
  user: null,
  userList: null,
  recentUserList: null,
  deleteUserResp: null,
  recentCertificatesList: null,
  certificatesList: null,
  loginResp: null,
  loading: "idle",
  error: null,
  savedData: null,
  userAnalytics: null,
  uploadedContentResp: [],
  addCertificateResp: null,
  userListFrCertificates: null,
  ageGroupsList: [],
}

const states = {
  pending: "pending",
  idle: "idle"
}

export const AuthReducer = createReducer(initialState, {
  [LoginRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [LoginRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loginResp: action.payload,
      user: action.payload.user,
      token: action.payload.token,
      loading: states.idle,
      error: null
    }
  },
  [LoginRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  // upload content
  [UploadContentRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [UploadContentRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null
    }
  },
  [UploadContentRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  //Ban User
  [BanUserRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [BanUserRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null
    }
  },
  [BanUserRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  // get uploaded content
  [DeleteLevelRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [DeleteLevelRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null
    }
  },
  [DeleteLevelRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  // get uploaded content
  [GetUploadedContentRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetUploadedContentRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      uploadedContentResp: action.payload,
      error: null
    }
  },
  [GetUploadedContentRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  [GetAgeGroupsRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetAgeGroupsRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      ageGroupsList: action.payload,
      error: null
    }
  },
  [GetAgeGroupsRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  [GetUserAnalyticsRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetUserAnalyticsRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      userAnalytics: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [GetUserAnalyticsRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  //  get user list 
  [GetUserListRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetUserListRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      userList: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [GetUserListRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  // get recent users list 
  [GetRecentUserListRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetRecentUserListRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      recentUserList: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [GetRecentUserListRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  //  get recent uploaded certificates
  [GetRecentCertificatesRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetRecentCertificatesRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      recentCertificatesList: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [GetRecentCertificatesRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  // get certificate list request
  [GetCertificateListRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetCertificateListRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      certificatesList: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [GetCertificateListRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  // GetUserListForCertificateRequest
  [GetUserListForCertificateRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [GetUserListForCertificateRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      userListFrCertificates: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [GetUserListForCertificateRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  [ExportUserListRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [ExportUserListRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null
    }
  },
  [ExportUserListRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  [RedeemedUserRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [RedeemedUserRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null
    }
  },
  [RedeemedUserRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },

  // Delete User Request
  [DeleteUserRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [DeleteUserRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      deleteUserResp: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [DeleteUserRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  //
  // Add certificate request
  [AddCertificateRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null
    }
  },
  [AddCertificateRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      addCertificateResp: action.payload,
      loading: states.idle,
      error: null
    }
  },
  [AddCertificateRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
      // error: action.error
    }
  },
  // ==--== 
  [SaveDataRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
    }
  },
  [SaveDataRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      savedData: action.payload,
    }
  },
  [SaveDataRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle
    }
  },
  [LogoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState
    }
  }
})
