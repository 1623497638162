import React from 'react'
import { ThreeDots } from 'react-loader-spinner';

function CustomLoader() {
     return (
          <div style={styles.loaderCont}>
               <div style={styles.loader}>
                    <ThreeDots color="#F1C400" height={100} width={100} />
               </div>
          </div>
     )
}

const styles = {
     loaderCont: {
          height: "100vh",
          width: "100vw",
          display: "flex",
          position: 'absolute',
          justifyContent: 'center',
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          alignItems: 'center',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: "auto",
          zIndex: 999,
     },
     loader: {
          display: "flex",
          width: 150,
          justifyContent: 'center',
     }
}

export default CustomLoader