import AppImages from "./AppImages"

export const RouteNames = {
  login: "/",
  dashboard: "/dashboard",
  listOfUsers: "/list-of-users",
  uploadCertificate: "/upload-certificate",
  uploadContent: "/upload-content",
  pageNotFound: "*",
}

export const AppConstants = {
  BASE_URL: "https://billowing-waterfall-35422.botics.co"
  // BASE_URL: "http://192.168.1.64:8001" // lan 
  // BASE_URL: "http://192.168.1.64:8000" // lan 
  // BASE_URL: "http://192.168.100.11:8000" // tp link
}

export const getImageSource = itemImage => {
  return itemImage != null && itemImage != undefined && itemImage != ""
    ? itemImage
    : AppImages.avatar
}

export const localeDateFormat = {
  locale: 'en-US',
  options: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
}