import { useEffect, } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RouteNames } from "../../Helpers/AppConstants"
import AppImages from "../../Helpers/AppImages"
import LoginForm from "./components/LoginForm"
import "./login.scss"

function Login() {
  const navigate = useNavigate()
  const { user } = useSelector(state => state.AuthReducer)

  useEffect(() => {
    if (user) {
      navigate(RouteNames.dashboard)
    }
  }, [])

  return (
    <>
      <div className="wrapper">
        <div className="wrapper__inner">
          <img
            src={AppImages.logo}
            className="wrapper__inner--logo"
            alt="logo"
          />
          <div className="wrapper__inner--card">
            <p className="title">Welcome to</p>
            <p className="title">Ardent Money Ninja Admin</p>
            <img
              src={AppImages.loginPageImage}
              className="login-image"
              alt="logo"
            />
            <p className="heading mt-4">Sign In</p>

            <LoginForm />
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
