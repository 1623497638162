import React, { useEffect, useState } from 'react'
import AppImages from '../../../Helpers/AppImages'
import styles from "./DashboardComp.module.scss"
import { Table, Divider } from 'antd';
import { GetRecentCertificatesRequest, GetRecentUserListRequest, GetUserAnalyticsRequest, } from '../../../api/Authentication/AuthReducer';
import { AppLogger } from '../../../Helpers/AppLogger';
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { getImageSource, localeDateFormat } from '../../../Helpers/AppConstants';
import { get } from 'lodash';
import SideMenu from '../SideMenu';

function DashboardComp() {
     const dispatch = useDispatch()
     const { loading, token, recentUserList, recentCertificatesList, userAnalytics } = useSelector(state => state.AuthReducer)
     const [recCertificatelist, setRecCertificatelist] = useState([])
     const [finalUserList, setFinalUserList] = useState([])
     useEffect(() => {
          getRecentUsersList()
          getRecentCertificate()
          getUserAnalytics()
     }, [])

     const getRecentUsersList = () => {
          dispatch(GetRecentUserListRequest({ token: token }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("Get Recent UserListRequest", response)
               })
               .catch(err => {
                    AppLogger("Get Recent UserListRequest", err)
               })
     }

     const getRecentCertificate = () => {
          dispatch(GetRecentCertificatesRequest({ token: token }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("Get Recent CertificatesRequest", response)
               })
               .catch(err => {
                    AppLogger("Get Recent CertificatesRequest", err)
               })
     }

     const getUserAnalytics = () => {
          dispatch(GetUserAnalyticsRequest({ token: token }))
               .then(unwrapResult)
               .then(response => {
                    AppLogger("Get User Analytics Request response", response)
               })
               .catch(err => {
                    AppLogger("Get User Analytics Request error", err)
               })
     }
     useEffect(() => {
          var finalList = []
          if (recentCertificatesList) {
               recentCertificatesList.forEach(element => {
                    AppLogger("ABC element", element)
                    finalList.push({
                         expiration_date: get(element, "expiration_date", ""),
                         issue_date: get(element, "issue_date", ""),
                         organization_name: get(element, "organization_name", ""),
                         name: get(element, "user.name", ""),
                         file: get(element, "file", ""),
                    })
               });
               AppLogger("Final List", finalList)
               setRecCertificatelist(finalList)
          }
     }, [recentCertificatesList])


     useEffect(() => {
          var arrayList = []
          if (recentUserList) {
               recentUserList.forEach(element => {
                    arrayList.push({
                         avatar: get(element, "avatar", ""),
                         name: get(element, "name", ""),
                         date_joined: get(element, "date_joined", ""),
                         email: get(element, "parent", null) != null ? get(element, "parent.email", "") : get(element, "email", ""),
                         zip_code: get(element, "parent", null) != null ? get(element, "parent.zip_code", "") : get(element, "zip_code", ""),
                         completed_videos: get(element, "completed_videos", ""),
                         completion_date: get(element, "completion_date", ""),
                         last_login: get(element, "last_login", ""),
                         last_used: get(element, "last_used", ""),
                    })
               });
               setFinalUserList(arrayList)
          }
     }, [recentUserList])

     const columns = [
          {
               title: () => { return <div><p>Image</p></div> },
               dataIndex: 'avatar',
               render: (e) =>
                    <>
                         <img style={{ height: "40px", width: "40px", borderRadius: 25 }} src={getImageSource(e?.image)} />
                    </>
               ,
          },
          {
               title: () => { return <div><p>Name</p></div> },
               dataIndex: 'name',
          },
          {
               title: () => { return <div><p>Reg. Date</p></div> },
               dataIndex: 'date_joined',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p>Email</p></div> },
               dataIndex: 'email',
          },
          {
               title: () => { return <div><p>Email Update Date</p></div> },
               dataIndex: 'emailupdatedate',
          },
          {
               title: () => { return <div><p>ZIP</p></div> },
               dataIndex: 'zip_code',
          },
          {
               title: () => { return <div><p># of Videos Completed</p></div> },
               dataIndex: 'completed_videos',
          },
          {
               title: () => { return <div><p>Completion Date</p></div> },
               dataIndex: 'completion_date',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options)
               }

          },
          {
               title: () => { return <div><p >Last Login</p></div> },
               dataIndex: 'last_login',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          },
          {
               title: () => { return <div><p >Last Use</p></div> },
               dataIndex: 'last_used',
               render: (date) => {
                    if (!date) {
                         return "";
                    }
                    return new Date(date).toLocaleString(localeDateFormat.locale, localeDateFormat.options);
               }
          }
     ];
     // const data = []
     const data = finalUserList;

     const columnsT2 = [
          {
               title: () => { return <div><p>Name</p></div> },
               dataIndex: 'name',
          },
          {
               title: () => { return <div><p>Issue Date</p></div> },
               dataIndex: 'issue_date',
          },
          {
               title: () => { return <div><p>Expiration Date</p></div> },
               dataIndex: 'expiration_date',
          },
          {
               title: () => { return <div><p>Upload</p></div> },
               key: 'upload',
               // fixed: 'right',
               // width: 100,
               render: (e) => <button className={styles.certificateBtn} onClick={() => window.open(e.file, "_blank")}>Certificate</button>,
          }
     ];
     const dataT2 = recCertificatelist;

     return (
          <div className={styles.mainContainer}>
               <SideMenu selectedIndex={0} />
               <div className={styles.container}>
                    <p className={styles.heading}>Users Analytics</p>
                    <div className={`${styles.analyticsContainer} row`}>
                         {/* <div className={styles.analyticsContainer}> */}
                         {userAnalytics != null && userAnalytics.map((item, index) =>
                              <div key={index}
                                   style={{ backgroundColor: item.color ? item.color : "#F1C400" }}
                                   className={`${styles.analyticsCon} col-12 col-sm-12 col-md-4`}
                              >
                                   <div className={styles.upperCon}>
                                        {index == 0 ? <img
                                             src={AppImages.listOfUsers}
                                             // src={item.icon}
                                             className={styles.iconStyleb} alt="listOfUsers" /> : <img
                                             src={AppImages.ListOfCheckedUsers}
                                             // src={item.icon}
                                             className={styles.iconStyles} alt="listOfUsers" />}
                                        {/* // <img
                                   //      src={AppImages.listOfUsers}
                                   //      // src={item.icon}
                                   //      className={styles.iconStyles} alt="listOfUsers" /> */}
                                        <p className={styles.amount}>{item.value}</p>
                                   </div>
                                   <p className={styles.title}>{item.title}</p>
                              </div>
                         )}
                    </div>

                    <p className={styles.heading}>Recently Registered Users</p>
                    <Table pagination={false} className={styles.table} columns={columns} dataSource={data} size="middle"
                         scroll={{ x: 1000 }}
                    />

                    <p className={styles.heading}>Recently Uploaded Certificates</p>
                    <Table pagination={false} className={styles.table} columns={columnsT2} dataSource={dataT2} size="middle"
                         scroll={{ x: 1000 }}
                    />
               </div>
          </div>
     )
}

export default DashboardComp