import React from 'react'
import "./pagenotfound.scss"

export default function PageNotFound() {
    return (
        <div className="wrapper">
            <div className="wrapper__inner">
                <p className='wrapper__inner__title'>Page Not Found</p>
            </div>
        </div>
    )
}
