import React from 'react';
import {
     MDBBtn,
     MDBModal,
     MDBModalDialog,
     MDBModalContent,
     MDBModalHeader,
     MDBModalTitle,
     MDBModalBody,
     MDBModalFooter,
} from 'mdb-react-ui-kit';

export default function CustomModal(props) {
     const { onBtnClick, desc, btnTitle, showModal, setShowModal } = props

     return (
          <>
               {/* <MDBBtn onClick={toggleShow}>Launch static backdrop modal</MDBBtn> */}
               <MDBModal staticBackdrop tabIndex='-1' show={showModal} setShow={setShowModal}>
                    <MDBModalDialog>
                         <MDBModalContent>
                              <MDBModalHeader>
                                   {/* <MDBModalTitle>modal title</MDBModalTitle> */}
                                   <MDBBtn className='btn-close' color='none' onClick={() => setShowModal(false)}></MDBBtn>
                              </MDBModalHeader>
                              <MDBModalBody style={fontStyl.style}>{desc}</MDBModalBody>
                              <MDBModalFooter>
                                   <MDBBtn style={fontStyl.style} color='secondary' onClick={() => setShowModal(false)}>
                                        No
                                   </MDBBtn>
                                   <MDBBtn style={fontStyl.style} onClick={() => onBtnClick()}>{btnTitle}</MDBBtn>
                              </MDBModalFooter>
                         </MDBModalContent>
                    </MDBModalDialog>
               </MDBModal>
          </>
     );
}

const fontStyl = {
     style: {
          fontFamily: "GothamMedium",
     },
}