const AppImages = {
  logo: require("./Images/main-logo.png"),
  loginPageImage: require("./Images/login-image.png"),
  avatar: require("./Images/avatar.png"),
  dashboard: require("./Images/SideMenuImages/dashboard.png"),
  dashboardBlue: require("./Images/SideMenuImages/dashboardBlue.png"),
  listOfUsers: require("./Images/SideMenuImages/listOfUsers.png"),
  listOfUser: require("./Images/SideMenuImages/listOfUser.png"),
  ListOfCheckedUsers: require("./Images/SideMenuImages/ListOfCheckedUsers.png"),
  userInfo: require("./Images/SideMenuImages/userInfo.png"),
  userInfoWhite: require("./Images/SideMenuImages/userInfoWhite.png"),
  uplaodContentWhite: require("./Images/SideMenuImages/uplaodContentWhite.png"),
  uplaodContent: require("./Images/SideMenuImages/uplaodContent.png"),
  analyticsWhite: require("./Images/SideMenuImages/analyticsWhite.png"),
  analytics: require("./Images/SideMenuImages/analytics.png"),
  questionsWhite: require("./Images/SideMenuImages/questionsWhite.png"),
  questions: require("./Images/SideMenuImages/questions.png"),
  setResultsWhite: require("./Images/SideMenuImages/setResultsWhite.png"),
  setResults: require("./Images/SideMenuImages/setResults.png"),
  uploadCertificateWhite: require("./Images/SideMenuImages/uploadCertificateWhite.png"),
  uploadCertificate: require("./Images/SideMenuImages/uploadCertificate.png"),
  search: require("./Images/search-icon.png"),
  block: require("./Images/block-icon.png"),
  ban: require("./Images/ban-icon.png"),
  delete: require("./Images/delete-icon.png"),
  checked: require("./Images/check.png"),
  unchecked: require("./Images/unchecked.png"),
  deleteIcon: require("./Images/delete.png"),
  remove: require("./Images/remove.png"),
  add: require("./Images/add.png")
}

export default AppImages
