import React, { useState, useEffect } from "react"
import { AppLogger } from "../../../Helpers/AppLogger"
import CheckBox from "./CheckBox"
import styles from "./UploadContent.module.scss"


export default function QuestionBox(props) {
  const { questionItem, handleQuestionChange, questionIndex, showRemoveQuestion, handleRemoveQuestion } = props
  const [options, setOptions] = useState([])

  // AppLogger("questionItem", questionItem);

  useEffect(() => {
    if (questionItem.option) {
      setOptions(questionItem.option)
    }
  }, [questionItem])

  const OptionChange = (value, ObjKey, index) => {
    if (ObjKey == "is_correct") {
      var array = []
      options.forEach((element) => {
        array.push({ ...element, is_correct: false })
      })
      const list = [...array]
      list[index][ObjKey] = value
      setOptions(list)
      handleQuestionChange(list, "option", questionIndex)
    } else {
      const list = [...options]
      list[index][ObjKey] = value
      setOptions(list)
      handleQuestionChange(list, "option", questionIndex)
    }
  }

  const addOption = () => {
    const list = [...options, { number: options.length + 1, value: "", is_correct: false }]
    setOptions(list)
    handleQuestionChange(list, "option", questionIndex)
  }

  const removeOption = index => {
    const list = [...options]
    list.splice(index, 1)
    setOptions(list)
    handleQuestionChange(list, "option", questionIndex)
  }

  return (
    <>
      <div className={styles.questionsData}>
        <input
          type={"text"}
          placeholder={"How can we saved the money?"}
          className={styles.inputCon}
          value={questionItem.value}
          onChange={(e) => handleQuestionChange(e.target.value, "value", questionIndex)}
        />
        <div className={styles.form}>
          {options.map((opItem, i) => (
            <CheckBox
              key={i}
              id="one"
              index={i}
              optionItem={opItem}
              handleOptChange={OptionChange}
              handleRemoveOption={() => removeOption(i)}
              showRemove={options.length > 1}
            />
          ))}
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: 12, flexWrap: 'wrap' }}>
          {/* {questionIndex != 0 &&
            <button onClick={() => onSubmit()} className={styles.uploadBtn}>
              Upload
            </button>
          } */}
          {options.length < 4 &&
            <button onClick={() => addOption()} className={styles.uploadBtn}>
              + Add Option
            </button>
          }
          {showRemoveQuestion &&
            <button onClick={() => handleRemoveQuestion()} className={styles.uploadBtn}>
              - Remove Question
            </button>
          }
        </div>
      </div>
    </>
  )
}
