import React, { useState } from "react"
import Button from "../../components/Button"
import { LoginRequest } from "../../../api/Authentication/AuthReducer"
import { unwrapResult } from "@reduxjs/toolkit"
import { RouteNames } from "../../../Helpers/AppConstants"
import {
  failureToastMessage,
  warningToastMessage
} from "../../../Helpers/ToastMessage"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const LoginForm = () => {
  const [email, setEmail] = useState(
    // "usama.ayaz+admin@crowdbotics.com", // server user
    // "usama.ayaz+2@crowdbotics.com" // local user
    ""
  )
  const [password, setPassword] = useState(
    // "Test123456@", // server user
    // "test123456" // local user
    ""
  )
  const { loading } = useSelector(state => state.AuthReducer)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleLogin = () => {
    const body = {
      username: email,
      password: password
    }

    if (email != "" && password != "") {
      dispatch(LoginRequest({ body: body }))
        .then(unwrapResult)
        .then(response => {
          // console.log(" === LoginRequestresponse === ", response)
          navigate(RouteNames.dashboard)
        })
        .catch(err => {
          console.log(" ==== LoginRequest Error ==== ", err)
          failureToastMessage("Unable to login!")
        })
    } else {
      warningToastMessage("Email and password is required.")
    }
  }
  return (
    <div className="form__container">
      <div className="form__container--input-wrapper">
        <p className="label">Email</p>
        <input
          className="text-input"
          type={"text"}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>

      <div className="form__container--input-wrapper">
        <p style={{ marginTop: 15 }} className="label">
          Password
        </p>
        <input
          className="text-input"
          type={"password"}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>

      <div className="form__container--action-wrapper">
        <div className="primary-btn">
          <Button
            handleClick={handleLogin}
            title="Sign In"
            // type="submit"
            // disabled={loading}
            loading={loading}
          />
        </div>
        {/* <div className="secondary-btn">
          <Button
            handleClick={() => null}
            title="Forgot Password"
            // type="submit"
            // disabled={loading}
            loading={loading}
          />
        </div> */}
      </div>
    </div>
  )
}

export default LoginForm
