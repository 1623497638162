import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import ConfigureStore from "./Store/Store";
import AppRoutes from "./routes/AppRoutes";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

const App = () => {
  const { store, persistor } = ConfigureStore();


  useEffect(() => {
    Cookies.set('csrftoken', '')
    Cookies.set('sessionid', '')

    // Cookies.remove('csrftoken')
    // Cookies.remove('sessionid')
    // console.log("we are hereee");
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Toaster />
        <AppRoutes />
      </PersistGate>
    </Provider>
  );
};

export default App;
