import React, { useEffect, useState } from "react"
import { failureToastMessage, successToastMessage, warningToastMessage } from "../../../Helpers/ToastMessage"
import { unwrapResult } from "@reduxjs/toolkit"
import { UploadContentRequest } from "../../../api/Authentication/AuthReducer"
import { useDispatch, useSelector } from "react-redux"
import { AppLogger } from "../../../Helpers/AppLogger"
import QuestionBox from "./QuestionBox"
import styles from "./UploadContent.module.scss"
import AppImages from "../../../Helpers/AppImages"

export default function Section(props) {
  const { sectionDetail, index, updatedQuestions, callUploadContentApi, selectedAgeGrp } = props
  const dispatch = useDispatch()
  const { loading, token } = useSelector(state => state.AuthReducer)

  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const [questionsList, setQuestionsList] = useState([
    {
      number: 1,
      value: "",
      option: [
        {
          number: 1,
          value: "",
          is_correct: false
        },
        {
          number: 2,
          value: "",
          is_correct: false
        },
        {
          number: 3,
          value: "",
          is_correct: false
        },
        {
          number: 4,
          value: "",
          is_correct: false
        }
      ]
    },
    {
      number: 2,
      value: "",
      option: [
        {
          number: 1,
          value: "",
          is_correct: false
        },
        {
          number: 2,
          value: "",
          is_correct: false
        },
        {
          number: 3,
          value: "",
          is_correct: false
        },
        {
          number: 4,
          value: "",
          is_correct: false
        }
      ]
    }
  ])

  useEffect(() => {
    handleQuestionsData()
  }, [sectionDetail])

  const handleQuestionsData = () => {
    if (sectionDetail.questions.length > 0) {
      setQuestionsList(sectionDetail.questions)
    }
  }

  const handleUploadContent = (body) => {
    dispatch(UploadContentRequest({ body: body, token: token }))
      .then(unwrapResult)
      .then(response => {
        AppLogger("Upload Content response", response)
        if (response) {
          callUploadContentApi()
          successToastMessage(response.message)
          setSelectedQuestion(0)
        }
      })
      .catch(err => {
        AppLogger("Upload Content error", err)
        if (err) { failureToastMessage(err.message) }
      })
  }

  const handleSubmit = () => {
    if (validateForm()) {
      if (sectionDetail.videoUrl != "" && sectionDetail.base64URL == "") {
        const body = {
          level: index + 1,
          questions: questionsList,
          age_id: selectedAgeGrp
        }
        handleUploadContent(body)
      } else if (sectionDetail.base64URL != "") {
        const body = {
          level: index + 1,
          video: sectionDetail.videoUrl,
          thumbnail: "",
          questions: questionsList,
          age_id: selectedAgeGrp
        }
        handleUploadContent(body)
      } else {
        warningToastMessage("Please select a video")
      }
    }
  }

  const validateForm = () => {
    var finalToast = ""
    questionsList.forEach((element) => {
      if (element.value == "") {
        finalToast = "Please fill out both questions"
      }
      var counter = 0
      element.option.forEach((opts) => {
        if (opts.value == "") {
          finalToast = "Please enter all answers"
        }
        if (opts.is_correct == false) {
          counter = counter + 1
        }
      })
      if (counter == element.option.length) {
        finalToast = "Please mark at least 1 answer as true for both questions"
      }
    })
    if (finalToast.length != 0) {
      warningToastMessage("Please enter all fields")
      return false
    }
    return true
  }

  const updateQuestionValue = (value, key, qindex) => {
    const list = [...questionsList]
    list[qindex][key] = value
    setQuestionsList(list)
    updatedQuestions(list, "questions", index)
  }

  useEffect(() => {
    AppLogger("questionsList===>>>>>>>>", questionsList);
  }, [questionsList])

  const handleAddQuestion = () => {
    const list = [
      ...questionsList,
      {
        number: questionsList.length + 1,
        value: "",
        option: [
          {
            number: 1,
            value: "",
            is_correct: false
          },
          {
            number: 2,
            value: "",
            is_correct: false
          },
          {
            number: 3,
            value: "",
            is_correct: false
          },
          {
            number: 4,
            value: "",
            is_correct: false
          }
        ]
      }
    ]
    setQuestionsList(list)
    updatedQuestions(list, "questions", index)
    setSelectedQuestion(list.length - 1)
  }

  const removeQuestions = qindex => {
    const list = [...questionsList]
    list.splice(qindex, 1)
    setQuestionsList(list)
    updatedQuestions(list, "questions", index)
    setSelectedQuestion(0)
  }

  return (
    <div>
      <div className={styles.questCont}>
        <div className={styles.questions}>
          {questionsList.map((item, i) => (
            <div
              style={{
                backgroundColor:
                  i == selectedQuestion ? "#EEEEEE" : "transparent",
                cursor: "pointer",
              }}
              onClick={() => setSelectedQuestion(i)}
              className={styles.innerQuest}
            >
              <p style={{ marginBottom: 0, marginTop: 0 }}>Question {i + 1}</p>
            </div>
          ))}
          {questionsList.length < 4 &&
            <div
              style={{ backgroundColor: "transparent", cursor: "pointer", }}
              className={styles.innerQuest}
            >
              <img
                onClick={() => handleAddQuestion()}
                style={{ width: 16, height: 16, alignSelf: 'normal' }}
                src={AppImages.add}
              />
            </div>
          }
        </div>

        {questionsList.length > 0 &&
          <QuestionBox
            questionIndex={selectedQuestion}
            handleQuestionChange={updateQuestionValue}
            questionItem={questionsList[selectedQuestion]}
            showRemoveQuestion={questionsList.length > 1}
            handleRemoveQuestion={() => removeQuestions(selectedQuestion)}
          />
        }
      </div>
      <div className={styles.bottomContainer}>
        {/* {questionsList.length < 4 &&
          <button className={styles.questionsBtn} onClick={() => handleAddQuestion()}>
            + Add Question
          </button>
        } */}
        <button className={styles.questionsBtn} onClick={() => handleSubmit()}>
          Upload
        </button>
      </div>
    </div>
  )
}
