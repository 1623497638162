import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { RouteNames } from "../../../Helpers/AppConstants"
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { LogoutRequest } from "../../../api/Authentication/AuthReducer"
import { AppLogger } from "../../../Helpers/AppLogger"
import AppImages from "../../../Helpers/AppImages"
import styles from "./sideMenu.module.scss"
import CustomLoader from "../Loader"
import Cookies from 'js-cookie';

function SideMenu(props) {
  const { selectedIndex } = props
  const { loading, loginResp, user } = useSelector(state => state.AuthReducer)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [itemColor, setItemColor] = useState("white")
  const [listColor, setListColor] = useState("white")
  const SideMenu = [
    {
      id: 1,
      title: "Dashboard",
      icon: AppImages.dashboard,
      selectedIcon: AppImages.dashboardBlue,
      path: RouteNames.dashboard
    },
    {
      id: 2,
      title: "List of Users",
      icon: AppImages.listOfUser,
      selectedIcon: AppImages.listOfUsers,
      path: RouteNames.listOfUsers
    },
    {
      id: 3,
      title: "Upload Content",
      icon: AppImages.uplaodContent,
      selectedIcon: AppImages.uplaodContentWhite,
      path: RouteNames.uploadContent
    },
    {
      id: 4,
      title: "Upload Certificate",
      icon: AppImages.uploadCertificate,
      selectedIcon: AppImages.uploadCertificateWhite,
      path: RouteNames.uploadCertificate
    },
  ]

  useEffect(() => {
    if (loginResp == null) {
      navigate(RouteNames.login)
    }
  }, [loginResp])

  const renderItem = (item) => {
    navigate(item.path)
  }

  const handleSelectedScr = index => {
    if (index == selectedIndex && selectedIndex == 1) {
      return {
        backgroundColor: "#0E6B8C",
        borderTopLeftRadius: "100px",
        borderBottomLeftRadius: "100px",
        marginLeft: "3px",
        color: "#fff"
      }
    }
    // if (index == selectedIndex && selectedIndex == 8) {
    //   return {
    //     backgroundColor: "#75787B",
    //     borderTopLeftRadius: "100px",
    //     borderBottomLeftRadius: "100px",
    //     marginLeft: "3px",
    //     color: "#fff"
    //   }
    // }
    if (index == selectedIndex && selectedIndex > 1 && selectedIndex < 4) {
      return {
        backgroundColor: "#A0C23B",
        borderTopLeftRadius: "100px",
        borderBottomLeftRadius: "100px",
        marginLeft: "3px",
        color: "#fff"
      }
    }
    return {}
  }

  const handleLogout = () => {
    dispatch(LogoutRequest())
      .then(unwrapResult)
      .then(response => {
        AppLogger(" === LogoutRequest Response === ", response)
        Cookies.set('csrftoken', '')
        Cookies.set('sessionid', '')
        navigate(RouteNames.login)
      })
      .catch(err => {
        AppLogger(" ==== LogoutRequest Error ==== ", err)
      })
  }

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.userContainer}>
            <img
              src={AppImages.avatar}
              className={styles.avatarStyl}
              alt="avatar"
            />
            {user && <p className={styles.username}>{user.name ? user.name : "Admin Ardent"}</p>}
          </div>
          <div style={{ backgroundColor: "#66435A", height: 1.3 }} />
        </div>
        <div style={style.upperListCont}>
          <div className={styles.list} style={{ backgroundColor: listColor }}>
            {SideMenu.map((item, index) => (
              <div key={index} style={{ backgroundColor: itemColor }} onClick={() => renderItem(item)}>
                <div
                  style={handleSelectedScr(index)}
                  className={styles.listItem}
                >
                  <img
                    src={selectedIndex == index ? item.selectedIcon : item.icon}
                    className={styles.iconStyles}
                    alt="avatar"
                  />
                  <p
                    className={styles.listText}
                    style={{ color: selectedIndex == index && index == 0 ? "#0E6B8C" : "" }}
                  >
                    {item.title}
                  </p>
                </div>

                <div className={styles.listSeparator} />
              </div>
            ))}
          </div>
          <div className={styles.logoutCont}>
            <p className={styles.logout} onClick={() => handleLogout()}>
              Logout
            </p>
          </div>
        </div>
      </div>
      {loading == "pending" &&
        <CustomLoader />
      }
    </>
  )
}

const style = {
  upperListCont: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: "86%",
  }
}

export default SideMenu
